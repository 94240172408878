<template>
<div class="common_sub_container">
    <h2 class="sections_to_hide"> Postal Items Sales Tracker</h2>
    <section class="sections_to_hide">
        <div class="form_section1">
            <table>
                <tr>
                    <td>
                        <div class="label_and_element_wrapper">
                            <label>
                            Choose Period
                            <span></span>
                            </label>
                            <flat-pickr
                            class="date_for_pos_fixed"
                            v-model="daterange"
                            :config="configDate"
                            placeholder="Date"
                            name="date_range"
                            @on-close="dateChanged">
                            </flat-pickr>
                        </div>
                    </td>
                    <td>
                        <div class="label_and_element_wrapper">
                            <label>
                            Document Types
                            <span></span>
                            </label>
                            <select v-model="post_data.postal_document_type_id">
                                <option value="">All</option>
                                <option v-for="(postaldocument,i) in postaldocuments" :key="'postaldocuments'+i" :value="postaldocument.id">{{postaldocument.postal_document_type}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                    <a class="link_bt bt_save" @click="fetchReport">Search</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>
    </section>

    <section class="reports_to_print">
        <div class="despatch_summary_wrapper"  v-if="Object.keys(reports).length > 0">
            <header>
                <h3>Postal Items Sales Tracker {{ auth().user_type != 'super_admin' ? ` - ${auth().branch}` : '' }}</h3>
                <div class="right_part">
                <a title="Download" ref="download" @click="downloadReport">
                    <i class="material-icons">arrow_drop_down_circle</i>
                </a>
                <a title="Print" @click="printRecord">
                    <i class="material-icons">print</i>
                </a>
                </div>
            </header>
            <div class="despatch_summary_table_container">
                <template v-for="(report, label) in reports">
                    <table>
                        <thead>
                            <tr>
                                <td><b>{{label}}</b></td>
                                <td v-for="(data, date) in report.dates">
                                    <template v-if="'Total' == date">
                                        <b>{{ date }}</b>
                                    </template>
                                    <template v-else>
                                        <b>{{date | formatDateLabel}}</b>
                                    </template>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Qty Purchased</td>
                                <td v-for="(data, date) in report.dates">{{data.items.qty_purchased}}</td>
                            </tr>
                            <tr>
                                <td>Purchase Value</td>
                                <td v-for="(data, date) in report.dates">{{data.items.purchase_value.toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td>Qty Sold</td>
                                <td v-for="(data, date) in report.dates">{{data.items.qty_sold}}</td>
                            </tr>
                            <tr>
                                <td>Sell Value</td>
                                <td v-for="(data, date) in report.dates">{{data.items.sell_value.toFixed(2)}}</td>
                            </tr>
                            <tr>
                                <td>Qty Stock in Hand</td>
                                <td v-for="(data, date) in report.dates">
                                    {{data.items.stock_in_hand}}
                                </td>
                            </tr>
                            <tr>
                                <td>Stock Value</td>
                                <td v-for="(data, date) in report.dates">
                                    {{data.items.stock_value.toFixed(2) | thousandSeparator }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br v-if="Object.keys(reports).length > 1">
                </template>
            </div>
        </div>
        <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>
</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
export default {
    data() {
        return {
            daterange: [
                moment(new Date()).format("YYYY-MM-01"),
            ],
            api_url:'',
            show_data:false,
            post_data:{
                from: moment(new Date()).format("YYYY-MM-01"),
                to: moment(new Date()).format("YYYY-MM-") + moment().daysInMonth(),
                postal_document_type_id:'',
            },
            postaldocuments:[],
            configDate: {
                mode: "single",
                format: "d-m-Y",
                altFormat: "M-Y",
                altInput: true
            },
            reports:[],
        }
    },
    filters: {
        formatDateLabel(value) {
            if (!value) {
                return;
            }

            return moment(String(value)).format('DD-MMM-YY')
        },
        thousandSeparator(value) {
            if (!value) return '';
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    },
    components: {
        flatPickr,
    },
    async created() {
        this.api_url = process.env.VUE_APP_API_URL;
        this.$emit("breadCrumbData", ["Reports", " Postal Items Sales Tracker"]);
        await this.fetchPostalDocument()
    },
    methods: {
        dateChanged(daterange) {
            this.post_data.from = moment(daterange[0]).format("YYYY-MM-01");
            this.post_data.to = moment(daterange[0]).endOf('month').format("YYYY-MM-DD");
        },
        fetchReport() {
            this.axios.post("/api/reports/get-postal-item-sale-tracker", this.post_data)
                .then(response => {
                    this.show_data = true;
                    this.reports = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        fetchPostalDocument(){
            this.axios.get("/api/postaldocument")
            .then(response => {
                this.postaldocuments = response.data.postal_document_types;
            })
            .catch(error =>{
                this.toast.error();
            });
        },
        downloadReport() {
            let method = "POST";
            let input;
            let form = document.createElement("form");
            form.setAttribute("method", method);
            form.setAttribute(
                "action",
                this.api_url +
                "/api/reports/get-postal-item-sale-tracker/download?token=" +
                localStorage.token
            );
            Object.keys(this.post_data).forEach(item => {
                input = document.createElement("input");
                input.type = "text";
                input.name = item;
                input.value = this.post_data[item];
                form.appendChild(input);
            });
            this.$refs.download.appendChild(form);
            form.submit();
            this.$refs.download.removeChild(form);
        },
        printRecord() {
            window.print();
        }
    }
}
</script>

<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.despatch_summary_table_container {
    overflow: scroll;
}

.despatch_summary_table_container table tr td {
    width: 100px;
}
.scoped_cell_width_adj td {
  width: 25%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container tr:hover {
    background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>
@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>